body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.stoneImg {
  z-index: 9;
  position: absolute;
}

.image-view {
  text-align: center !important;
  margin-top: 0px !important;
  position: unset !important;
  height: 100% !important;
}

.garageDoorImg {
  z-index: 9;
  position: absolute;
}
.roofImg {
  z-index: 9;
  position: absolute;
}

.roofIcon {
  z-index: 0;
  position: absolute;
  padding: 7px 0px 0px 7px;
  cursor: pointer;
}

.roofIconview {
  z-index: 13;
  position: absolute;
  padding: 7px 0px 0px 7px;
  cursor: pointer;
}

.close-img {
  position: absolute;
  z-index: 9999;
  top: 0px;
  right: 47px;
  font-size: -webkit-xxx-large;
  cursor: pointer;
}

.ReactModalPortal
  .ReactModal__Overlay.ReactModal__Overlay--after-open
  .ReactModal__Content.ReactModal__Content--after-open
  .madal-container
  .body
  .pdf-document
  .image-section-new {
  text-align: center !important;
  margin-top: 0px !important;
  position: initial !important;
  height: 100% !important;
}

.text-style {
  margin-top: -17px;
}
.text-font {
  font-size: 14px;
  margin: 10px;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .col-md-3.colorimg {
    position: absolute;
    left: 160px !important;
  }
  .our_service {
    padding-top: 0px !important;
  }

  .colorschema {
    order: 2;
  }
  .svgimage {
    order: 1;
  }

  .external_design-image {
    margin-top: 0px !important;
  }

  #masking-image {
    visibility: visible !important;
  }

  .render-image-bottom {
    margin-top: 6px !important;
    margin-bottom: 50px !important;
  }

  .plan_name_container .plan_name {
    font-size: 18px !important;
  }

  .pdf-generate-container .pdf-generate-btn {
    width: 128px !important;
    font-size: 12px !important;
  }

  .render-image-container {
    overflow: inherit !important;
    height: 245px !important;
    flex-direction: column !important;
  }

  .ColorSchemes-root-1 .MuiGrid-container {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .external_design-filterScroll {
    height: 211px !important;
    width: 97% !important;
  }
  .external_design-filterBody {
    width: 73%;
  }

  .external_design-filterCaption {
    font-size: 12px !important;
  }

  .external_design-filterRow {
    height: 448px !important;
  }
  .external_design-filterHead {
    width: 73% !important;
  }
  .external_design-filterHead h5 {
    font-size: 15px !important;
  }
  .external_design-colorText h6 {
    font-size: 11px !important;
  }

  .external_design-colorRow {
    height: 264px !important;
    max-width: 174px !important;
    left: 198px !important;
    top: 31px !important;
  }
  .external_design-colorCol {
    max-height: 233px !important;
  }
  .external_design-colorText p {
    font-size: 12px !important;
  }
  .external_design-filterBox {
    max-width: 245px !important;
    margin-left: 18px !important;
    margin-top: -7px !important;
  }
  .our_service-sliderArrow .swiper-button-prev {
    display: none !important;
  }
  .our_service-sliderArrow .swiper-button-next {
    display: none !important;
  }
  .ReactModalPortal
    .ReactModal__Overlay.ReactModal__Overlay--after-open
    .ReactModal__Content.ReactModal__Content--after-open {
    position: absolute !important;
    left: 0px !important;
    width: 100% !important;
    top: 0px !important;
    bottom: 0px !important;
  }
  .ReactModalPortal
    .ReactModal__Overlay.ReactModal__Overlay--after-open
    .ReactModal__Content.ReactModal__Content--after-open
    .madal-container
    .body
    .pdf-document
    .image-section {
    height: 143px !important;
  }
  .ReactModalPortal
    .ReactModal__Overlay.ReactModal__Overlay--after-open
    .ReactModal__Content.ReactModal__Content--after-open
    .madal-container
    .body
    .pdf-document
    .heading-section
    .heading {
    font-size: 18px !important;
  }

  .ReactModalPortal
    .ReactModal__Overlay.ReactModal__Overlay--after-open
    .ReactModal__Content.ReactModal__Content--after-open
    .madal-container
    .body
    .pdf-document
    .details-section
    .color-scheme-container
    .color-scheme-label {
    font-size: 17px !important;
  }

  .ReactModalPortal
    .ReactModal__Overlay.ReactModal__Overlay--after-open
    .ReactModal__Content.ReactModal__Content--after-open
    .madal-container
    .body
    .pdf-document
    .details-section
    .color-scheme-container
    .color-scheme-name {
    font-size: 17px !important;
  }

  .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    height: 390px !important;
  }
  div#isModal {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .our_service {
    padding-top: 0px !important;
  }

  .colorschema {
    order: 2;
  }
  .svgimage {
    order: 1;
  }

  .external_design-image {
    margin-top: 0px !important;
  }

  .render-image-bottom {
    margin-top: -9px !important;
    margin-bottom: 83px !important;
  }

  .render-image-container {
    overflow: inherit !important;
    height: 570px !important;
    margin-top: -12px !important;
  }
  .ReactModalPortal
    .ReactModal__Overlay.ReactModal__Overlay--after-open
    .ReactModal__Content.ReactModal__Content--after-open {
    position: absolute !important;
    left: 0px !important;
    width: 100% !important;
    inset: 0px 0px !important;
    top: 0px !important;
    bottom: 0px !important;
  }

  .ReactModalPortal
    .ReactModal__Overlay.ReactModal__Overlay--after-open
    .ReactModal__Content.ReactModal__Content--after-open
    .madal-container
    .body
    .pdf-document
    .image-section {
    height: 290px !important;
  }
  .ReactModalPortal
    .ReactModal__Overlay.ReactModal__Overlay--after-open
    .ReactModal__Content.ReactModal__Content--after-open
    .madal-container
    .body
    .pdf-document
    .heading-section
    .heading {
    font-size: 18px !important;
  }

  .ReactModalPortal
    .ReactModal__Overlay.ReactModal__Overlay--after-open
    .ReactModal__Content.ReactModal__Content--after-open
    .madal-container
    .body
    .pdf-document
    .details-section
    .color-scheme-container
    .color-scheme-label {
    font-size: 17px !important;
  }

  .ReactModalPortal
    .ReactModal__Overlay.ReactModal__Overlay--after-open
    .ReactModal__Content.ReactModal__Content--after-open
    .madal-container
    .body
    .pdf-document
    .details-section
    .color-scheme-container
    .color-scheme-name {
    font-size: 17px !important;
  }

  .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    height: 390px !important;
  }

  div#isModal {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }
}

@media only screen and (min-width: 414px) and (orientation: portrait) {
  .render-image-container {
    height: 80% !important;
  }
  .external_design-colorRow {
    max-width: 198px !important;
    left: 215px !important;
  }
  .external_design-filterBody {
    width: 82%;
  }
  .external_design-filterHead {
    width: 82% !important;
  }
  div#isModal {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
  .render-image-container {
    overflow: initial !important;
    height: 590px !important;
  }
  .external_design {
    padding: 50px 0px 50px 0px !important;
  }
  .external_design-image {
    height: 556px !important;
  }
  div#isModal {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
}
}

/* Medium devices (desktops, 992px and up) */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  /* @media only screen  and (min-width : 1824px) { */
  .render-image-container {
    overflow: initial !important;
    height: 590px !important;
  }
  .external_design {
    padding: 50px 0px 50px 0px !important;
  }
  .external_design-image {
    height: 556px !important;
  }
  div#isModal {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
}
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}

@media screen and (min-width: 740px) and (max-width: 1023px) {
  .external_design-image {
    height: 770px;
  }
}

@media only screen and (width: 1440px) and (height: 900px) {
  .render-image-container {
    height: 87% !important;
    right: 20px !important;
  }
  .mh_footer {
    margin-top: 40px !important;
  }
  .external_design-colorRow {
    left: 290px;
  }
}

@media only screen and (width: 1280px) and (height: 800px) {
  .external_design-colorRow {
    max-width: 47% !important;
    padding: 25px 0px !important;
  }
  .render-image-container {
    height: 79% !important;
  }
}

@media only screen and (width: 1280px) and (height: 950px) {
  .external_design-colorRow {
    max-width: 47% !important;
    padding: 25px 0px !important;
  }
  .render-image-container {
    height: 79% !important;
  }
}

div#viewimg-streling {
  position: relative !important;
  width: 100% !important;
  overflow-x: hidden !important;
  inset: 0px !important;
  border-radius: 0px !important;
  padding: 0px !important;
  top: 0px !important;
  height: auto !important;
  left: 0 !important;
}

div#viewimg {
  position: relative !important;
  width: 85% !important;
  overflow-y: hidden !important;
  inset: 0px !important;
  border-radius: 7px !important;
  padding: 0px !important;
  top: 8px !important;
  height: 97% !important;
  left: 99px !important;
}

div#viewimg
  .ReactModal__Content
  .ReactModal__Content--after-open
  .madal-container
  .body
  .pdf-document
  .image-section {
  text-align: center;
  margin-top: 0px !important;
  position: relative;
  height: 465px;
}

div#viewimg-streling
  .ReactModal__Content
  .ReactModal__Content--after-open
  .madal-container
  .body
  .pdf-document
  .image-section-new {
  text-align: center;
  margin-top: 0px !important;
  position: relative;
  height: 465px;
}

div#viewimg-streling
  .ReactModalPortal
  .ReactModal__Overlay.ReactModal__Overlay--after-open
  .ReactModal__Content.ReactModal__Content--after-open
  .madal-container
  .body
  .pdf-document
  .image-section-new {
  text-align: center;
  margin-top: 0px;
  position: relative;
  height: 465px;
}

div#viewimg-streling
  .ReactModalPortal
  .ReactModal__Overlay.ReactModal__Overlay--after-open
  .ReactModal__Content.ReactModal__Content--after-open
  .madal-container
  .body
  #pdf-document {
  padding: 0px 0px !important;
}

div#viewimg
  .ReactModalPortal
  .ReactModal__Overlay.ReactModal__Overlay--after-open
  .ReactModal__Content.ReactModal__Content--after-open
  .madal-container
  .body
  #pdf-document {
  padding: 0px 0px !important;
}

div#viewimg-streling .madal-container .body #pdf-document {
  padding: 0px 0px;
}

div#viewimg .madal-container .body #pdf-document {
  padding: 0px 0px;
}

div#viewimg
  .ReactModalPortal
  .ReactModal__Overlay.ReactModal__Overlay--after-open
  .ReactModal__Content.ReactModal__Content--after-open
  .madal-container
  .body
  .pdf-document
  #image-section-new {
  text-align: center;
  margin-top: 0px !important;
  position: relative;
  height: auto;
}

div#viewimg-streling
  .ReactModalPortal
  .ReactModal__Overlay.ReactModal__Overlay--after-open
  .ReactModal__Content.ReactModal__Content--after-open
  .madal-container
  .body
  .pdf-document
  #image-section-new {
  text-align: center;
  margin-top: 0px !important;
  position: relative;
  height: auto;
}

div#viewimg .madal-container {
  height: 100%;
  position: static;
  top: 0;
  bottom: 0;
}

div#viewimg-streling .madal-container {
  height: 100%;
  position: static;
  top: 0;
  bottom: 0;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

div#viewimg .image-section {
  text-align: center;
  margin-top: 0px !important;
  position: relative;
  height: 465px;
}

div#viewimg-streling .image-section {
  text-align: center;
  margin-top: 0px !important;
  position: relative;
  height: 465px;
}

div#custom-color {
  display: flex;
  justify-content: flex-start;
}

#custom-color strong {
  position: absolute;
  left: -1px;
  top: 4px;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  position: absolute !important;
  min-width: 0px !important;
  padding: 0px 0px !important;
  right: 5px !important;
  border-radius: 50% !important;
}

div[role="button"] {
  display: none !important;
}

button.ColorPicker-MuiButtonBase-root.ColorPicker-MuiButton-root.ColorPicker-MuiButton-text {
  border: 1px solid black !important;
  padding: 2px !important;
}

button.ColorPicker-MuiButtonBase-root.ColorPicker-MuiButton-root.ColorPicker-MuiButton-text:hover {
  background-color: blue !important;
  color: white !important;
}

span.ColorPicker-MuiButton-label div {
  border: 2px solid gray !important;
  border-radius: 50% !important;
  margin-left: -2.5px !important;
  margin-top: -3px !important;
}

button.ColorPicker-MuiButtonBase-root.ColorPicker-MuiButton-root.ColorPicker-MuiButton-contained,
button.ColorPicker-MuiButtonBase-root.ColorPicker-MuiButton-root.ColorPicker-MuiButton-contained:focus,
button.ColorPicker-MuiButtonBase-root.ColorPicker-MuiButton-root.ColorPicker-MuiButton-contained:focus-visible,
button.ColorPicker-MuiButtonBase-root.ColorPicker-MuiButton-root.ColorPicker-MuiButton-contained:hover,
button.ColorPicker-MuiButtonBase-root.ColorPicker-MuiButton-root.ColorPicker-MuiButton-contained:visited,
button.ColorPicker-MuiButtonBase-root.ColorPicker-MuiButton-root.ColorPicker-MuiButton-contained:active {
  border: none;
  box-shadow: none;
  background: none;
  border-radius: 50%;
  margin-left: 3px;
}

.ColorPicker-MuiButton-label {
  border-radius: 50% !important;
  padding-left: 5px !important;
  padding: 3px 10px 0px 11px !important;
}

.ColorPicker-MuiButton-label:focus-visible,
.ColorPicker-MuiButton-label:focus {
  border-radius: 50% !important;
  padding-left: 5px !important;
  padding: 11px 10px 0px 11px !important;
}

span.slider-checkbox input[type="checkbox"] {
  transform: scale(0.9) !important;
}

.checkbox-view {
  display: flex;
}

.pdf-document span.heading-capital {
  text-transform: capitalize !important;
}

.showimg {
  display: block !important;
}

.hideimg {
  display: none !important;
}

/* responsive view for full screen modal */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  div#viewimg {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 47% !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 47% !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 21% !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 21% !important;
  }
  div#isModal {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
}
}

@media only screen and (width: 414px) and (height: 896px) and (-webkit-device-pixel-ratio: 2) {
  div#viewimg {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 47% !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 47% !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 21% !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 21% !important;
  }
  div#isModal {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
}
}

@media only screen and (width: 414px) and (height: 896px) and (-webkit-device-pixel-ratio: 3) {
  div#viewimg {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 21% !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 21% !important;
  }
  div#isModal {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
}
}

@media only screen and (width: 375px) and (height: 667px) and (-webkit-device-pixel-ratio: 2) {
  div#viewimg {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 40% !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 40% !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 21% !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 21% !important;
  }
  div#isModal {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
}
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  div#viewimg {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 40% !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 40% !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 21% !important;
  }

  /* ipad ratio 1  */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1) {
    div#viewimg {
      position: relative !important;
      width: 100% !important;
      overflow-y: hidden !important;
      inset: 0px !important;
      border-radius: 0px !important;
      padding: 0px !important;
      top: 0% !important;
      height: 100% !important;
      left: 0px !important;
      padding-top: 20% !important;
    }

    div#viewimg-streling {
      position: relative !important;
      width: 100% !important;
      overflow-y: hidden !important;
      inset: 0px !important;
      border-radius: 0px !important;
      padding: 0px !important;
      top: 0% !important;
      height: 100% !important;
      left: 0px !important;
    }

    #viewimg span.close-img {
      position: absolute !important;
      top: 21% !important;
    }

    #viewimg-streling span.close-img {
      position: absolute !important;
      top: 21% !important;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1) {
    div#viewimg {
      position: relative !important;
      width: 100% !important;
      overflow-y: hidden !important;
      inset: 0px !important;
      border-radius: 0px !important;
      padding: 0px !important;
      top: 0% !important;
      height: 100% !important;
      left: 0px !important;
    }

    div#viewimg-streling {
      position: relative !important;
      width: 100% !important;
      overflow-y: hidden !important;
      inset: 0px !important;
      border-radius: 0px !important;
      padding: 0px !important;
      top: 0% !important;
      height: 100% !important;
      left: 0px !important;
    }

    #viewimg span.close-img {
      position: absolute !important;
      top: 21% !important;
    }

    #viewimg-streling span.close-img {
      position: absolute !important;
      top: 21% !important;
    }
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 21% !important;
  }
  div#isModal {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
}
}

/* ipad ratio 2  */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  div#viewimg {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 28% !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 28% !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 21% !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 21% !important;
  }
  div#isModal {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
 }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  div#viewimg {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 21% !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 21% !important;
  }
}

@media only screen and (min-device-width: 800px) and (max-device-width: 1280px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  div#viewimg {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 21% !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 0% !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 23% !important;
    font-size: 32px !important;
    right: 27px !important;
  }
}

@media screen and (min-width: 732px) and (max-width: 412px) and (orientation: landscape) {
  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 0% !important;
  }
}

/* iphone XS view */
/* Media query for iPhone XS in landscape mode */
@media only screen and (max-device-width: 812px) and (max-device-height: 375px) and (orientation: landscape) {
  /* .ReactModal__Overlay.ReactModal__Overlay--after-open {
    position: fixed !important;
    max-height: 392px !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
  } */

  div#viewimg {
    position: fixed !important;
    width: 100% !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0px !important;
    max-height: 392 !important;
    left: 0px !important;
    overflow-y: scroll !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: scroll !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
  }

  div#viewimg .image-section {
    text-align: center;
    margin-top: 0px !important;
    position: relative;
    height: auto !important;
  }

  div#viewimg .image-section #background-image {
    text-align: center;
    margin-top: 0px !important;
    position: absolute;
    height: auto !important;
    width: auto !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 2% !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 2% !important;
  }
}

@media only screen and (max-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) {
  div#viewimg {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 21% !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 21% !important;
  }
}

/* Media query for targeting a hypothetical iPhone 14 Plus */
/* @media only screen and (max-device-width: 428px) and (max-device-height: 926px) and (orientation: portrait) {
  div#viewimg {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 39% !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 39% !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 21% !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 23% !important;
  }
} */

/* iPhone 14 plus */
/* Portrait mode */
@media only screen and (max-width: 428px) and (max-height: 926px) {
  div#viewimg {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 39% !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 39% !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 21% !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 23% !important;
  }
}

/* Landscape mode */
@media only screen and (max-width: 926px) and (max-height: 428px) {
  div#viewimg {
    position: relative !important;
    width: 100% !important;
    overflow-y: scroll !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: scroll !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 0 !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 0 !important;
  }
  div#viewimg .image-section #background-image {
    position: absolute !important;
    top: 0 !important;
    width: auto !important;
    height: auto !important;
    left: 0 !important;
  }
}

/* iPhone XS */
/* Portrait mode */
@media only screen and (max-width: 375px) and (max-height: 812px) {
  div#viewimg {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 39% !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: hidden !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
    padding-top: 39% !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 21% !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 23% !important;
  }
}

/* Landscape mode */
@media only screen and (min-width: 812px) and (min-height: 375px) {
  div#viewimg {
    position: relative !important;
    width: 100% !important;
    overflow-y: scroll !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
  }

  div#viewimg-streling {
    position: relative !important;
    width: 100% !important;
    overflow-y: scroll !important;
    inset: 0px !important;
    border-radius: 0px !important;
    padding: 0px !important;
    top: 0% !important;
    height: 100% !important;
    left: 0px !important;
  }

  #viewimg span.close-img {
    position: absolute !important;
    top: 0 !important;
  }

  #viewimg-streling span.close-img {
    position: absolute !important;
    top: 0 !important;
  }
  div#viewimg .image-section #background-image {
    position: absolute !important;
    top: 0 !important;
    width: auto !important;
    height: auto !important;
    left: 0 !important;
  }
}

/* .li-list .col-md-6 {
  width: 50%;
} */